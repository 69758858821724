import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BlogPost = ({ post }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
    <p className="text-sm text-gray-500 mb-2">{post.date}</p>
    <h3 className="text-xl font-semibold mb-2">
      <Link 
        to={`/blog/${post.id}`} 
        className="text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded"
      >
        {post.title}
      </Link>
    </h3>
    <p className="text-base text-gray-600 mb-2">{post.author}</p>
    <p className="text-base mb-4">{post.excerpt}</p>
    <div className="flex justify-between items-center">
      <span className="badge badge-secondary">{post.category}</span>
      <Link 
        to={`/blog/${post.id}`} 
        className="inline-flex items-center text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded px-2 py-1"
      >
        Read More
        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </Link>
    </div>
  </div>
);

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/blog-posts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedData);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the blog posts:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="text-center py-12 text-xl">Loading blog posts...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-xl text-error" role="alert">Error loading blog posts: {error}</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-12 text-gray-900">Lab Blog</h1>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {posts.map(post => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;