import React, { useState, useEffect } from 'react';
import { Users } from 'lucide-react';

const TeamMember = ({ member }) => (
  <div className="card bg-base-100 shadow-xl">
    <figure className="px-10 pt-10">
      {member.image ? (
        <img src={member.image} alt={member.name} className="rounded-full w-24 h-24 object-cover" />
      ) : (
        <Users className="w-24 h-24" />
      )}
    </figure>
    <div className="card-body items-center text-center">
      <h2 className="card-title">{member.name}</h2>
      <p className="font-semibold">{member.position}</p>
      <p className="text-sm">Researcher in {member.field}</p>
      <p className="text-sm mt-2">{member.bio}</p>
    </div>
  </div>
);

const AboutPage = () => {
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/team-members.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setTeamData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the team members:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="text-center py-12">Loading team information...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-error">Error loading team information: {error}</div>;
  }

  return (
    <div className="container mx-auto py-12">
      <h2 className="text-3xl font-bold mb-8 text-center">About Our Lab</h2>
      <p className="text-lg mb-12 text-center max-w-3xl mx-auto">
        We are dedicated to pushing the boundaries of scientific research and innovation. Our team of experts collaborates across disciplines to tackle some of the most pressing challenges in quantum computing, climate science, and AI ethics.
      </p>
      
      <div className="mb-16">
        <h3 className="text-2xl font-bold mb-6 text-center">Principal Investigator</h3>
        <div className="max-w-md mx-auto">
          <TeamMember member={teamData.principalInvestigator} />
        </div>
      </div>
      
      <div className="mb-16">
        <h3 className="text-2xl font-bold mb-6 text-center">Project Coordinators</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {teamData.projectCoordinators.map((member) => (
            <TeamMember key={member.id} member={member} />
          ))}
        </div>
      </div>
      
      <div>
        <h3 className="text-2xl font-bold mb-6 text-center">Research Assistants</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {teamData.researchAssistants.map((member) => (
            <TeamMember key={member.id} member={member} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;