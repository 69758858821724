// ContactPage.js
import React from 'react';

const ContactPage = () => (
  <div className="container mx-auto py-12">
    <h2 className="text-3xl font-bold mb-8 text-center">Contact Us</h2>
    <div className="card max-w-md mx-auto bg-base-100 shadow-xl">
      <div className="card-body">
        <form>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Name</span>
            </label>
            <input type="text" placeholder="Your name" className="input input-bordered" required />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input type="email" placeholder="Your email" className="input input-bordered" required />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Message</span>
            </label>
            <textarea className="textarea textarea-bordered" placeholder="Your message" required></textarea>
          </div>
          <div className="form-control mt-6">
            <button className="btn btn-primary">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default ContactPage;