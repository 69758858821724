import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ResearchPage from './components/ResearchPage';
import BlogPage from './components/BlogPage';
import BlogPostPage from './components/BlogPostPage';
import PublicationsPage from './components/PublicationsPage';
import PublicationPage from './components/PublicationPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navigation />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogPostPage />} />
            <Route path="/publications" element={<PublicationsPage />} />
            <Route path="/publications/:id" element={<PublicationPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <footer className="footer footer-center p-4 bg-base-300 text-base-content">
          <div>
            <p>&copy; {new Date().getFullYear()} Research Lab. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;