import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const PublicationPage = () => {
  const [publication, setPublication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch('/publications.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const foundPublication = data.find(p => p.id.toString() === id);
        if (foundPublication) {
          setPublication(foundPublication);
        } else {
          throw new Error('Publication not found');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the publication:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="text-center py-12">Loading publication...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-error">Error loading publication: {error}</div>;
  }

  if (!publication) {
    return <div className="text-center py-12">Publication not found</div>;
  }

  return (
    <div className="container mx-auto py-12 px-4">
      <Link to="/publications" className="btn btn-ghost mb-4">&larr; Back to Publications</Link>
      <article className="prose lg:prose-xl mx-auto">
        <h1>{publication.title}</h1>
        <p className="lead">{publication.authors.join(', ')}</p>
        <p><strong>{publication.journal}</strong>, {publication.year}</p>
        <p>{publication.abstract}</p>
        {publication.doi && (
          <p>
            <strong>DOI:</strong> <a href={`https://doi.org/${publication.doi}`} target="_blank" rel="noopener noreferrer">{publication.doi}</a>
          </p>
        )}
        {publication.pdf && (
          <p>
            <a href={publication.pdf} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Download PDF</a>
          </p>
        )}
      </article>
    </div>
  );
};

export default PublicationPage;