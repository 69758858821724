import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PublicationItem = ({ publication }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
    <p className="text-sm text-gray-500 mb-2">{publication.date}</p>
    <h3 className="text-xl font-semibold mb-2">
      <Link 
        to={`/publications/${publication.id}`} 
        className="text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded"
      >
        {publication.title}
      </Link>
    </h3>
    <p className="text-base text-gray-600 mb-4">{publication.authors.join(', ')}</p>
    <p className="text-base mb-4">{publication.abstract}</p>
    <div className="flex flex-wrap gap-2">
      {publication.areas.map((area, index) => (
        <span key={index} className="px-2 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">
          {area}
        </span>
      ))}
    </div>
    <div className="mt-4">
      <a 
        href={publication.pdfLink} 
        className="inline-flex items-center text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded px-2 py-1"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Download PDF for ${publication.title}`}
      >
        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
        Paper
      </a>
    </div>
  </div>
);

const PublicationsPage = () => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/publications.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPublications(sortedData);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the publications:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="text-center py-12 text-xl">Loading publications...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-xl text-error" role="alert">Error loading publications: {error}</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-12 text-gray-900">Publications</h1>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {publications.map(publication => (
            <PublicationItem key={publication.id} publication={publication} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicationsPage;