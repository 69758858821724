// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => (
  <div className="hero min-h-screen bg-base-200">
    <div className="hero-content text-center">
      <div className="max-w-md">
        <h1 className="text-5xl font-bold">Welcome to the CASCADE Lab</h1>
        <p className="py-6">Innovating for a Better Tomorrow</p>
        <Link to="/research" className="btn btn-primary">Explore Our Research</Link>
      </div>
    </div>
  </div>
);

export default HomePage;