import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ResearchProject = ({ project, isEven }) => (
  <div className={`flex flex-col ${isEven ? 'md:flex-row-reverse' : 'md:flex-row'} mb-16`}>
    <div className="md:w-1/2">
      <img 
        src={project.image} 
        alt={project.title} 
        className="w-full h-[500px] object-cover rounded-lg shadow-lg"
      />
    </div>
    <div className="md:w-1/2 flex flex-col justify-center p-6">
      <h2 className="text-3xl font-bold mb-4">{project.title}</h2>
      <p className="text-lg mb-6">{project.description}</p>
      <div className="flex flex-wrap gap-2 mb-6">
        {project.tags.map((tag, index) => (
          <span key={index} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-semibold">
            {tag}
          </span>
        ))}
      </div>
      <Link 
        to={`/research/${project.id}`}
        className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
      >
        Learn More
        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </Link>
    </div>
  </div>
);

const ResearchPage = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/research-projects.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setProjects(data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the research projects:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="text-center py-12 text-xl">Loading research projects...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-xl text-error" role="alert">Error loading research projects: {error}</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-12 text-center">Our Research</h1>
        {projects.map((project, index) => (
          <ResearchProject key={project.id} project={project} isEven={index % 2 === 0} />
        ))}
      </div>
    </div>
  );
};

export default ResearchPage;