import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const BlogPostPage = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch('/blog-posts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const foundPost = data.find(p => p.id.toString() === id);
        if (foundPost) {
          setPost(foundPost);
        } else {
          throw new Error('Post not found');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("There was a problem fetching the blog post:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="text-center py-12">Loading blog post...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-error">Error loading blog post: {error}</div>;
  }

  if (!post) {
    return <div className="text-center py-12">Blog post not found</div>;
  }

  return (
    <div className="container mx-auto py-12 px-4">
      <Link to="/blog" className="btn btn-ghost mb-4">&larr; Back to Blog</Link>
      <article className="prose lg:prose-xl mx-auto">
        <h1>{post.title}</h1>
        <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
          <span>{post.date}</span>
          <span>{post.author}</span>
        </div>
        <p className="lead">{post.excerpt}</p>
        {/* Add the full blog post content here */}
        <p>This is where the full content of the blog post would go. You can add multiple paragraphs, images, and other elements as needed.</p>
      </article>
    </div>
  );
};

export default BlogPostPage;